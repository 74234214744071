.spinner {
  --spinner-size: 40px;
  --spinner-speed: 700ms;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: stretch;
  flex: 1;
  align-items: center;
}
